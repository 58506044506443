<template>
  <div class="post-form form">
    <div class="cols" v-if="!busy">
      <div class="col form-metadata">
        <input type="hidden" v-model="post.id" />
        <div class="field">
          <label class="label"
            >Tytuł (unikalny) <span class="required">*</span></label
          >
          <div class="control">
            <input class="input" type="text" v-model="post.title" />
          </div>
        </div>
        <div class="field" v-if="post.slug">
          <label class="label">Slug</label>
          <div class="field-text">{{ post.slug }}</div>
        </div>
        <div class="field">
          <label class="label">Opis metadata</label>
          <div class="control">
            <textarea v-model="post.description"></textarea>
          </div>
        </div>
        <div class="field">
          <label class="label"
            >Data utworzenia <span class="required">*</span></label
          >
          <div class="control">
            <input type="date" v-model="post.createdAt" />
          </div>
        </div>
        <div class="cols">
          <div class="col">
            <div class="field">
              <div class="head">Status</div>
              <label class="radio">
                <input
                  name="r1"
                  type="radio"
                  value="1"
                  v-model="post.status"
                  :checked="post.status == 1"
                />
                Opublikowany
              </label>
              <label class="radio">
                <input
                  name="r1"
                  type="radio"
                  value="2"
                  v-model="post.status"
                  :checked="post.status == 2"
                />
                Ukryty
              </label>
              <label class="radio">
                <input
                  name="r1"
                  type="radio"
                  value="3"
                  v-model="post.status"
                  :checked="post.status == 3"
                />
                Szkic
              </label>
            </div>
          </div>
          <div class="col">
            <div class="field">
              <div class="head">Język</div>
              <label class="radio">
                <input
                  name="r2"
                  type="radio"
                  value="pl"
                  v-model="post.lang"
                  :checked="post.lang == 'pl'"
                />
                <svg class="iconx iconx-flag">
                  <use :href="require('@/assets/langs.defs.svg') + '#pl'"></use>
                </svg>
              </label>
              <label class="radio">
                <input
                  name="r2"
                  type="radio"
                  value="en"
                  v-model="post.lang"
                  :checked="post.lang == 'en'"
                />
                <svg class="iconx iconx-flag">
                  <use :href="require('@/assets/langs.defs.svg') + '#en'"></use>
                </svg>
              </label>
              <label class="radio">
                <input
                  name="r2"
                  type="radio"
                  value="de"
                  v-model="post.lang"
                  :checked="post.lang == 'de'"
                />
                <svg class="iconx iconx-flag">
                  <use :href="require('@/assets/langs.defs.svg') + '#de'"></use>
                </svg>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col form-editorjs">
        <div class="field">
          <div class="head">Treść</div>
          <div id="editorjs" class="editorjs"></div>
        </div>
      </div>
    </div>
    <div class="message" v-else>Trwa ładowanie składowych postu…</div>
  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import Delimiter from "@editorjs/delimiter";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import RawTool from "@editorjs/raw";
import slugify from "slugify";
import ArtDigression from "../core/components/digression";
// import endpoints from "../data/endpoints";
import store from "@/store";

export default {
  name: "PostForm",
  props: ["post", "busy"],
  data() {
    return {
      editor: null,
    };
  },
  methods: {
    editorInit(initData = []) {
      const IMAGE_UPLOAD_ENDPOINT = store.getters.getImageUploadEndpoint;
      // console.log('IMAGE_UPLOAD_ENDPOINT', IMAGE_UPLOAD_ENDPOINT);
      this.editor = new EditorJS({
        holder: "editorjs",
        placeholder: "Treść wpisu…",
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          header: {
            class: Header,
            inlineToolbar: ["link"],
            config: {
              placeholder: 'Treść nagłówka',
              levels: [2,3,4],
              defaultLevel: 2
            }
          },
          list: {
            class: List,
            inlineToolbar: ["link", "bold"],
          },
          delimiter: Delimiter,
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: IMAGE_UPLOAD_ENDPOINT, // Your backend file uploader endpoint
              },
              additionalRequestHeaders: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            },
          },
          digression: ArtDigression,
          raw: RawTool,
        },
        data: initData,
        defaultBlock: 'paragraph'
      });
    },
    retriveData() {
      if (
        (typeof this.post.id === "undefined" || this.post.id == null) &&
        typeof this.post.title !== "undefined" &&
        this.post.title != ""
      )
        this.post.slug = slugify(this.post.title, { lower: true });
      return new Promise((resolve, reject) => {
        this.editor
          .save()
          .then((outputData) => {
            this.post.contentData = JSON.stringify(outputData);
            resolve(this.post);
          })
          .catch((e) => reject(e));
      });
    },
  },
  created() {
    // this.editorInit(data);
    console.log("PostForm created");
  },
  destroyed() {
    this.editor.destroy();
    console.log("PostForm & EditorJS destroyed");
  },
};
</script>

<style lang="scss">
.editorjs {
  // background-color: white;
}
%label-font {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  color: darken($brand-color, 6%);
}
.form {
  // font-size: 0.8rem;
  // max-width: 730px;
  margin: 1rem auto;
  // padding: 20px 40px;
  // background-color: #f8f8f8;
  > li {
    display: block;
    margin-top: 1em;
  }
  label {
    margin: 0 0 0.25em 0;
    display: block;
    @extend %label-font;
  }
  .head {
    @extend %label-font;
    display: inline-block;
  }
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="time"],
  select,
  textarea {
    box-sizing: border-box;
    border: 1px solid #bebebe;
    padding: 7px;
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
    width: 100%;
    font-size: 1rem;
    &:focus {
      box-shadow: 0 0 8px $brand-color;
      border: 1px solid $brand-color;
    }
  }
  textarea {
    resize: vertical;
    min-height: 200px;
    // font-family: $default-font;
    // &[name=source] {
    //   min-height: 160px;
    // }
  }
}
////
.cols {
  display: flex;
  width: 100%;
}
.col {
  width: 50%;
  padding: 12px;
}
.form-metadata {
  max-width: 400px;
}
.form-editorjs {
  max-width: 800px;
  flex: 1;
  // max-width: 730px;
  // margin: 0 auto;
  // padding: 20px 40px;
}
////
.message {
  padding: 1rem 0;
  font-size: 1.2rem;
  font-style: italic;
}
////
.editorjs {
  .digression {
    width: 100%;
    resize: vertical;
    padding: 10px;
    background-color: lightgray;
  }
  .ce-block {
    margin: 16px 0;
    background: white;
  }
  .ce-delimiter {
    clear: both;
    margin: 1.5em 0;
    text-align: center;
    border: none;
    height: 30px;
    &:before, &:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $brand-color;
      margin: 0 0.4em;
    }
  }
  .ce-header {
    margin-bottom: 0;
  }
  .image-tool {
    max-width: 540px;
    margin: 10px auto;
  }
  .image-tool__image {
    position: relative;
  }
  .image-tool__image-picture {
    margin: 0 auto;
  }
  .image-tool--floatToLeft, .image-tool--floatToRight {
    .image-tool__image:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: $brand-color;
      bottom: 0;
    }
  }
  .image-tool--floatToLeft {
    .image-tool__image:after {
      left: 0;
    }
  }
  .image-tool--floatToRight {
    .image-tool__image:after {
      right: 0;
    }
  }
  .image-tool--withBorder {
    .image-tool__image {
      border: 0;
    }
    .image-tool__image-picture {
      border: 8px solid #333;
    }
  }
}
</style>
